.button {
  background: radial-gradient(circle at left, #157cdf 50%, #57b251 50%);

  background-position: right;
  background-size: 250% 100%;
  transition: all 0.4s ease-in;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #157cdf;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: all 0.4s ease-in-out;
    left: -30px;
    opacity: 0;
    z-index: 0;
    transform-origin: center;
  }
}

.button:hover {
  &:after {
    transform: scale(14);
    opacity: 1;
  }
}
