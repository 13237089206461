.navBg {
  background: linear-gradient(
    270deg,
    rgba(21, 124, 223, 0.6) 0.04%,
    rgba(83, 182, 152, 0.6) 95.59%
  );

  backdrop-filter: blur(50px);
}

.transform {
  transition: all 0.5s ease-in-out;
}
