.back {
  background: linear-gradient(118deg, #1dd4b9 -1.62%, #003ebe 95.73%);
}

.slice1 {
  scale: 1.1;
}
.slice2 {
  scale: 1.1;
}

@media only screen and (min-width: 1750px) {
  .slice0 {
    height: 500px;
  }

  .slice1 {
    right: 250px;
    scale: 1.1;
  }

  .slice2 {
    right: 250px;
    scale: 1.1;
  }
}

@media only screen and (min-width: 2000px) {
  .slice0 {
    height: 550px;
  }
  .slice1 {
    top: 20px;
    right: 350px;
    scale: 1.2;
  }
  .slice2 {
    top: 20px;
    right: 350px;
    scale: 1.2;
  }
}

@media only screen and (min-width: 2500px) {
  .slice0 {
    height: 600px;
  }

  .slice1 {
    top: 20px;
    right: 450px;
    scale: 1.2;
  }

  .slice2 {
    top: 20px;
    right: 450px;
    scale: 1.2;
  }
}

@media only screen and (min-width: 3000px) {
  .slice0 {
    height: 650px;
  }

  .slice1 {
    top: 50px;
    right: 600px;
    scale: 1.3;
  }

  .slice2 {
    top: 50px;
    right: 600px;
    scale: 1.3;
  }
}
